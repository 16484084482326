import { languages } from "@/i18n/ui";
import { getPathAfterLocale, useTranslations } from "@/i18n/utils";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CaretDown, CaretUp, Check } from "@phosphor-icons/react";

type Props = {
  lang: "en" | "es";
  pathname: string;
};

export default function LanguageSelector({ lang, pathname }: Props) {
  const languageSelected = languages.find((l) => l.lang === lang);
  const t = useTranslations(lang);
  const lastSegmentCurrentPath = getPathAfterLocale(pathname);

  return (
    <Listbox value={languageSelected}>
      {({ open }) => (
        <div className="relative">
          <ListboxButton className="flex items-center gap-x-2 px-3 py-2 text-textSm text-white">
            {String(t(languageSelected.name))}

            {open ? (
              <CaretUp aria-hidden="true" size={12} color="white" />
            ) : (
              <CaretDown aria-hidden="true" size={12} color="white" />
            )}
          </ListboxButton>

          <ListboxOptions
            anchor={{ to: "bottom end", gap: "4px" }}
            transition
            className="z-50 origin-top rounded-md text-textXs shadow-md transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            {languages.map((item) => (
              <ListboxOption
                key={item.id}
                value={item}
                className="group flex w-full cursor-pointer items-center justify-between gap-x-2 bg-black px-3 py-1.5 text-white data-[focus]:backdrop-blur-xl"
              >
                <a
                  href={`${item.url}${lastSegmentCurrentPath}`}
                  className="block w-full truncate font-normal group-data-[selected]:font-semibold"
                >
                  {String(t(item.name))}
                </a>
                {item.lang === languageSelected.lang && (
                  <Check
                    aria-hidden="true"
                    size={20}
                    className="text-white-70"
                  />
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
}
